/* src/Login.css */
.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
  }
  
  .card {
    width: 100%;
    max-width: 400px;
  }